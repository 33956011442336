import { types } from 'src/types/types'

const initialState = {
  dataReports: [],
  paginationReport: {
    currentPage: -1,
    totalPages: 0,
  },
  statisticalData: {},
  dataFilter: [],
  dataCertificate: {
    id: "",
    created_at: "",
    detalles: {
      certificado: "",
      equipo: {
        sistema: {
          sistema_titulo: '',
          sistema_marca: '',
          sistema_modelo: '',
          sistema_serie: '',
          sistema_tension_maxima: '',
          sistema_corriente_carga_maxima: '',
          sistema_antiguedad: '',
          sistema_año_instalacion: '',
        },
        componente: {
          tubo_titulo: '',
          tubo_marca: '',
          tubo_modelo: '',
          tubo_serie: '',
          tubo_tension_maxima: '',
          tubo_corriente_carga_maxima: '',
          tubo_antiguedad: '',
          tubo_año_instalacion: '',
        }
      },
      fecha_control_calidad: "",
      informe: "",
      ubicacion: "",
      vigencia: "",
    }
  },
  activeReport: {
    nombre_reporte: '',
    numero_de_ot: '',
    observacion: '',
    fecha_control_calidad: '',
    datos_del_cliente: {
      razon_social: '',
      ruc: '',
      direccion: '',
      distrito: '',
      provincia: '',
      region: '',
      contactos: [],
      instalacion_direccion: '',
      instalacion_distrito: '',
      instalacion_provincia: '',
      instalacion_region: '',
      instalacion_ambiente: '',
    },
    sistema: {
      sistema_titulo: '',
      sistema_marca: '',
      sistema_modelo: '',
      sistema_serie: '',
      sistema_tension_maxima: '',
      sistema_corriente_carga_maxima: '',
      sistema_antiguedad: '',
      sistema_año_instalacion: '',
      tipo_rayos_x: '',
    },
    componente: {
      tubo_titulo: '',
      tubo_marca: '',
      tubo_modelo: '',
      tubo_serie: '',
      tubo_tension_maxima: '',
      tubo_corriente_carga_maxima: '',
      tubo_antiguedad: '',
      tubo_año_instalacion: '',
    },
    formato: [],
    machines: [],
    certificado_url: {
      message: '',
      fecha_emision: null,
      data: null,
      status: false,
      qr_code: null,
    },
    autor: { id: 0, autor_numero: "", autor_nombre: "", autor_apellido: "", autor_firma: "" },
    gerente: { gerente_numero: "", gerente_nombre: "", gerente_apellido: "", gerente_firma: "" },
    pruebas: [],
  },
  activeReportCliente: null,
  activeReportSensor: null,
  activeReportEquipo: null,
  statisticalData: {
    desaprobado: 0,
    aprobados: 0,
    noRevisados: 0,
    informesTotales: 0,
    fechas: [],
    reportePorFechas: {
    }
  },
}
export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reportsLoaded:
      return {
        ...state, paginationReport: {
          currentPage: action.payload.page_current,
          totalPages: action.payload.page_total,
        }, dataReports: action.payload.data,
      }
    case types.addReport:
      return {
        ...state,
        dataReports: [...state.dataReports, ...action.payload]
      }
    case types.statisticalLoaded:
      let desaprobados = action.payload.filter(el => el.certificado_url.message === "Certificado Desaprobado").length
      let aprobados = action.payload.filter(el => el.certificado_url.message === "Certificado Aprobado").length
      let noEvaluados = action.payload.filter(el => el.certificado_url.message === "Informe no evaluado").length
      let fechasReportes = action.payload.map(el => el.fecha_control_calidad)
      let reportePorFechas = fechasReportes.reduce((prev, cur) => ((prev[cur] = prev[cur] + 1 || 1), prev), {})

      return {
        ...state, statisticalData: {
          desaprobado: desaprobados,
          aprobados: aprobados,
          noRevisados: noEvaluados,
          informesTotales: desaprobados + aprobados + noEvaluados,
          fechas: fechasReportes,
          reportePorFechas: reportePorFechas
        }
      }

    case types.reportSetActive:
      return { ...state, activeReport: action.payload }
    case types.reportClienteUpdate:
      return {
        ...state, activeReport: { ...state.activeReport, ...action.payload },
        dataReports: state.dataReports.map((el) => (action.payload.id.toString() === el.id.toString() ? { ...el, ...action.payload } : el))
      }
    case types.reportSensorUpdate:
      return {
        ...state, activeReport: { ...state.activeReport, ...action.payload },
        dataReports: state.dataReports.map((el) => (action.payload.id.toString() === el.id.toString() ? { ...el, ...action.payload } : el))
      }
    case types.reportEquipoUpdate:
      return {
        ...state, activeReport: { ...state.activeReport, ...action.payload },
        dataReports: state.dataReports.map((el) => (action.payload.id.toString() === el.id.toString() ? { ...el, ...action.payload } : el))
      }
    case types.reportOpcionUpdate:
      return { ...state, activeReport: { ...state.activeReport, pruebas: state.activeReport.pruebas.map((prueba, index) => index === 0 ? action.payload.opciones_pruebas : prueba) } }
    case types.reportOperacionUpdate:
      return {
        ...state,
        activeReport: {
          ...state.activeReport,
          pruebas: state.activeReport.pruebas.map((prueba, index) => index === 1 ? action.payload.pruebas : prueba),
          valores_operaciones: action.payload.valores_operaciones,
          conclusiones: action.payload.conclusiones
        },
      }
    case types.certificateUpdate:
      return {
        ...state,
        activeReport: {
          ...state.activeReport, ...action.payload
        }
        , dataReports: state.dataReports.map((el) => (action.payload.id.toString() === el.id.toString() ? { ...el, ...action.payload } : el))
      }
    case types.detailsCertificate:
      return {
        ...state,
        dataCertificate: action.payload ? action.payload : initialState.dataCertificate
      }
    case types.reportImagesUpdate:
      return {
        ...state,
        activeReport: {
          ...state.activeReport, ...action.payload
        }
        , dataReports: state.dataReports.map((el) => (action.payload.id.toString() === el.id.toString() ? { ...el, ...action.payload } : el))

      }
    case types.reportImagesCSUpdate:
      const { infoImages, idReport } = action.payload
      return {
        ...state,
        activeReport: {
          ...state.activeReport, ...infoImages
        }
        , dataReports: state.dataReports.map((el) => (idReport.toString() === el.id.toString() ? { ...el, ...infoImages } : el))
      }

    default:
      return state
  }
}
