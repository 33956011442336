import { getUserLocation } from 'src/helper/helperDataUserAgent'
import { types } from 'src/types/types'
import Swal from 'sweetalert2'
import { fetchConToken, fetchSinToken } from '../helper/fetch'
import { cleanUserState } from './registro'
export const startLoginUsernamePassword = (username, password) => {
  return async (dispatch, getState) => {
    dispatch(StartChecking())
    try {

      const body = await fetchSinToken('api/auth/login/', {
        username, password, ubicacion: {
          ip_dispositivo: null,
          latitud: null,
          longitud: null,
          tipo_dispositivo: null,
        }
      }, 'POST')
      const resp = await body.json()
      if (resp?.error) {
        Swal.fire('Error!', resp.error, 'error')
      }
      if (resp.user) {
        const {
          user: { id, username, name, last_name, groups },
          token,
        } = resp
        localStorage.setItem('token', `${token}`)
        localStorage.setItem('refresh-token', `${resp['refresh-token']}`)
        dispatch(
          login({
            username: username,
            name: `${name} ${last_name}`,
            role: groups.includes(1) ? 'admin' : 'user',
            uid: id,
          }),
        )
        dispatch(CheckingFinish())
      }

      dispatch(CheckingFinish())
    } catch (error) {
      dispatch(CheckingFinish())
      console.log(error, 'Error usuario no logeado')
    }
  }
}


export const startAuthChecking = () => {
  return async (dispatch) => {
    dispatch(StartChecking())
    try {
      const resp = await fetchConToken('auth/user/')

      const body = await resp.json()
      if (body?.username) {
        const { username, name, last_name, groups, id } = body
        let dataUser = {
          username: username,
          name: `${name} ${last_name}`,
          role: groups.includes(1) ? 'admin' : 'user',
          uid: id,
        }
        dispatch(login(dataUser))
      }
      dispatch(CheckingFinish())
    } catch (error) {
      dispatch(CheckingFinish())
      console.log('error el token es invalido')
      console.log(error)
    }
  }
}

export const logout = () => ({ type: types.authLogout })

export const login = (user) => ({
  type: types.authLogin,
  payload: user,
})

const StartChecking = () => ({
  type: types.authChecking,
})

const CheckingFinish = () => ({
  type: types.authCheckingFinish,
})

export const startLogout = () => {
  return async (dispatch) => {
    dispatch(StartChecking())
    try {
      const resp = await fetchConToken(
        'auth/logout/',
        { refresh: localStorage.getItem('refresh-token') },
        'POST',
      )
      await resp.json()
      localStorage.clear()
      dispatch(logout())
      dispatch(CheckingFinish())
      dispatch(cleanUserState())
    } catch (error) {
      localStorage.clear()
      dispatch(logout())
      dispatch(cleanUserState())
      dispatch(CheckingFinish())
      console.log(error)
    }
  }
}
