import { types } from "src/types/types"

const initialState = {
    tiempoRestante: 21600,
    tiempoInicial: 21600,
    tiempoToleracia: 300,
}

export const timeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.sessionTime:
            return { ...state, tiempoRestante: action.payload }
        default:
            return state
    }
}

