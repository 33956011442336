const BaseUrl = process.env.REACT_APP_API_URL
const domainUrl = process.env.REACT_APP_API_DOMAIN
const fetchSinToken = (endpoint, data, method = 'GET') => {
  const url = `${domainUrl}/${endpoint}` //localhost:4000/api/
  if (method === 'GET') {
    return fetch(url)
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }
}

const fetchConToken = (endpoint, data, method = 'GET') => {
  const url = `${BaseUrl}/${endpoint}` //localhost:4000/api/
  const token = localStorage.getItem('token') || ''
  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  }
}


const fetchURLConToken = (endpoint, data, method = 'GET') => {
  const url = `${endpoint}` //localhost:4000/api/
  const token = localStorage.getItem('token') || ''
  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  }
}


const fetchURLSinToken = (endpoint, data, method = 'GET') => {
  const url = `${endpoint}` //localhost:4000/api/
  if (method === 'GET') {
    return fetch(url, {
      method,
    })
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }
}



const fetchArchivoConToken = (endpoint, data, method = 'GET') => {
  const url = `${BaseUrl}/${endpoint}` //localhost:4000/api/
  const token = localStorage.getItem('token') || ''
  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } else {
    return fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    })
  }
}






export { fetchConToken, fetchSinToken, fetchURLConToken, fetchURLSinToken, fetchArchivoConToken }