import { types } from "src/types/types";

const initialState = {
    certificate: null
}
export const qrReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.qrDataLoaded:
            return { ...state, certificate: action.payload }
        default:
            return state
    }
}
